import axios, { AxiosResponse } from 'axios';
import { getAxiosError, getDeviceInfo } from 'common/utils';
import AlertService from 'common/AlertService';
import qs from 'qs';
import type {
  PackagesMeta,
  ActiveSession,
  CreatingAccessTokenInquiry,
  ResponseContainer,
  Meta,
  CreateAccessTokenResponse,
  User,
  // Ticket,
  UserFormData,
  UserIspInfo,
  PaymentListType,
  PackageType,
  SelectedPackage,
  SubmitVoteRequest,
  // PlayTokenRequest,
  Content,
  SuccessSelectPackage,
  ConditionalPropResponse,
  // CommentReactionResponse,
  // CommentReactionRequest,
  buyCinemaTicketRequest,
  CompactConditionalProps,
  paymentGateway,
  PaymentTransaction,
  ContentType,
  historyData,
  PackageListType,
  continueWatchingData,
  popUpMessageData,
  MultiProfileSelectorProps,
  MultiProfileSwitch,
  MultiProfilePin,
  getOtpAfterDelete,
  ProfilePinInterface,
  SwitchToNewProfileType,
  ProfileResetPinInterface,
  IAvatarGroupType,
  UpdateMultiProfile,
  CinemaOnlineSelectedPackage,
  CinemaOnlineTransaction,
} from './types';
import lscache from 'lscache';
import { GlobalState } from 'common/CacheProvider';
import getConfig from 'next/config';
import { getUniqId } from 'common/utils/getDeviceInfo';
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();
const BASE_URL = publicRuntimeConfig.NEXT_PUBLIC_API_BASE_URL;
// clientSession.defaults.headers.get['x-ip-country'] = 'US';
const clientSession = axios.create();
clientSession.defaults.baseURL = BASE_URL;
clientSession.defaults.timeout = 120000;
clientSession.defaults.paramsSerializer = (params) => qs.stringify(params, { indices: false });
clientSession.interceptors.request.use(
  (config) => {
    const VERSION = publicRuntimeConfig.NEXT_PUBLIC_VERSION;
    const cachedState = lscache.get('state') as GlobalState;
    const device_id = getUniqId();
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-platform'] = 'Web';
    if (cachedState && cachedState.access_token) {
      config.headers.Authorization = `Bearer ${cachedState.access_token}`;
    }
    if (VERSION) {
      config.headers['version-number'] = VERSION;
    }
    if (cachedState && cachedState.user) {
      config.headers['user-id'] = cachedState.user?.id;
    }
    if (device_id) {
      config.headers['device-id'] = device_id;
    }
    if (cachedState && cachedState?.profile?.profileAccessToken) {
      config.headers['X-Profile-Token'] = `${cachedState?.profile?.profileAccessToken}`;
    }
    return config;
  },
  (config) => {
    return config;
  },

);
clientSession.interceptors.response.use(
  ({ data, ...response }: AxiosResponse) => ({ ...response, ...data }),
  (error) => {
    if (error.response.data.meta.operation_result_code === 40038 || error.response.data.meta.operation_result_code === 40039 || error.response.data.meta.operation_result_code === 40040) {
      AlertService.error(getAxiosError(error), false, 6000);
    } else {
      AlertService.error(getAxiosError(error));
    }
    if (error.response.status === 401 && error.response.data.meta.operation_result === 'unauthorized_sso') {
      if (localStorage) {
        localStorage.setItem('lscache-state', '{"user":null,"access_token":null, "profile": null}');
        localStorage.removeItem('subscriptionAlertClosedTime');
        localStorage.removeItem('lscache-selected_pined_profile');
      }
      Sentry.setUser(null);
      window.location.replace(error.response.data.meta.next_url);
    } else if (error.response.status === 401 && error.response.data.meta.operation_result !== 'unauthorized_sso') {
      if (error.response.status === 401 && error.response.data.meta.operation_result !== 'profile_unauthorized') {
        if (localStorage) {
          localStorage.setItem('lscache-state', '{"user":null,"access_token":null, "profile": null}');
          localStorage.removeItem('subscriptionAlertClosedTime');
          localStorage.removeItem('lscache-selected_pined_profile');
        }
        Sentry.setUser(null);
        if (window.location.pathname !== '/') {
          window.location.replace(`/login?redirect=${window?.location?.pathname + window?.location?.search}`);
        } else {
          window.location.reload();
        }
      } else if (error.response.status === 401 && error.response.data.meta.operation_result === 'profile_unauthorized') {
        const state = localStorage.getItem('lscache-state') || '';
        const parsedState = JSON.parse(state);
        const newState = { ...parsedState, profile: null };
        localStorage.setItem('lscache-state', JSON.stringify(newState));
        window.location.replace('/profile');
      }
    }
    if (error.response.status === 403 && error.response.data.meta.operation_result === 'profile_not_have_permission') {
      const state = localStorage.getItem('lscache-state') || '';
      const parsedState = JSON.parse(state);
      const newState = { ...parsedState, profile: null };
      localStorage.setItem('lscache-state', JSON.stringify(newState));
      window.location.replace('/profile');
    }
    return Promise.reject(error);
  },
);

export { clientSession };
export const tokenUrl = '/access-token/users/';
export const selfUrl = '/users/_self';

export const getCurrentUser = () => clientSession.get<never, ResponseContainer<User>>(selfUrl);

export const getUserApproaches = (msisdn: string) => (
  clientSession.get<never, ResponseContainer<CreatingAccessTokenInquiry>>(tokenUrl + msisdn + '/approaches')
);

export const setPasswordForced = ({ password }: { password: string; }) => (
  clientSession.post<never, ResponseContainer<User>>(selfUrl + '/password/_current', { password })
);

export const setPassword = ({ password }: { password: string; }) => (
  clientSession.put<never, ResponseContainer<User>>(selfUrl + '/password', { password })
);

export const changePassword = ({ new_password, current_password }: { new_password: string, current_password: string; }) => (
  clientSession.post<never, ResponseContainer<User>>(selfUrl + '/password', { new_password, current_password })
);

export const getOtpCode = (
  { msisdn }: { msisdn: string; },
) => clientSession.get<never, ResponseContainer<{ ttl: string; }>>(`${tokenUrl + msisdn} /otp`);

export const loginByPassword = ({ msisdn, password }) => (
  clientSession.post<never, ResponseContainer<CreateAccessTokenResponse>>(
    `${tokenUrl + msisdn}/password`,
    { password, device: getDeviceInfo() },
  )
);

export const loginByOtpCode = ({
  msisdn, otp, tracker_id = undefined, video_content_id = undefined,
}) => clientSession.post<never, ResponseContainer<CreateAccessTokenResponse>>(
  `${tokenUrl + msisdn}/otp`,
  {
    otp, tracker_id, video_content_id,
    device: getDeviceInfo(),
  },
);

export const killOtherSessionsAndLogin = (
  { msisdn, sessions, access_token }: { msisdn: string, sessions: ActiveSession[], access_token: string; },
) => clientSession.post<never, ResponseContainer<CreateAccessTokenResponse>>(
  tokenUrl + msisdn,
  { sessions },
  { headers: { Authorization: `Bearer ${access_token}` } },
);

export const logout = () => clientSession.delete<never, ResponseContainer<null>>('/access-token/users/_self');

export const userRemoteAccess = ({ code, sessions = [] }: { code: string, sessions?: ActiveSession[]; }) => (
  clientSession.post<never, ResponseContainer<{ other_sessions?: ActiveSession[]; } | undefined>>(
    '/access-token/users/remote-access/' + code,
    { sessions, device: getDeviceInfo() },
  )
);
// export const getHlsfilesUrl = ({ id, file_id }) => clientSession.get(
//   `/video-contents/${id}/files/${file_id}/url`,
// );
// export const getMp4FilesUrls = ({ id, file_id }) => clientSession.get(
//   `/video-contents/${id}/files/${file_id}/mp4`,
// );
export const sendVideoPlayBack = ({ id, file_id, current_time }) => clientSession.post(
  `/video-contents/${id}/files/${file_id}/playback`, { current_time },
);

export const postContentComments = ({ contentId, comment }: { contentId: string, comment: string; }) => (
  clientSession.post(`/video-contents/${contentId}/comments`, { text: comment })
);

// export const postCommentReaction = ({ contentId, id, type }: CommentReactionRequest) => (
//   clientSession.post<CommentReactionResponse>(`/video-contents/${contentId}/comments/${id}/reaction`, { type })
// );
// export const getPlayToken = ({ id, type, url, ...body }: PlayTokenRequest) => (
//   clientSession.post(url || `/video-contents/${id}/player/token/${type}`, body)
// );

export const getUserBookmark = (params: { video_content_type: string, offset?: number, count?: number }) => (
  clientSession.get<never, ResponseContainer<Content[]>>(selfUrl + '/bookmarks', { params })
);

export const saveUserBookmark = (video_content_id: string) => (
  clientSession.post('/users/_self/bookmarks', { video_content_id })
);

export const removeUserBookmark = (video_content_id: string) => (
  clientSession.delete(`/users/_self/bookmarks/${video_content_id}`)
);

export const saveUserStarRating = ({ user_rate, id }) => (
  clientSession.post(`/video-contents/${id}/rates`, { user_rate })
);

export const getConditionalProp = (id: string) => (
  clientSession.get<never, ResponseContainer<ConditionalPropResponse>>(`/video-contents/${id}/conditional-prop`)
);

export const getCompactConditionalProp = (body: string[]) => (
  clientSession.post<never, ResponseContainer<CompactConditionalProps[]>>('/video-contents/conditional-props-compact', body)
);

// export const fetchTickets = ({ count = 5, offset }) => (
//   clientSession.get<never, ResponseContainer<Ticket[]>>('/users/_self/tickets', { params: { count, offset } })
// );

export const fetchSessions = () => (
  clientSession.get<never, ResponseContainer<ActiveSession[]>>(tokenUrl + '_self/sessions')
);

export const getUserSubscriptions = () => (
  clientSession.get<never, ResponseContainer<PaymentListType[]>>('/users/_self/subscriptions')
);

export const postUserTicket = ({ title, body }: { title: string; body: string; }) => (
  clientSession.post<never, ResponseContainer<any>>('/users/_self/tickets',
    { title, body },
  )
);

export const getUserIsp = () => (
  clientSession.get<never, ResponseContainer<UserIspInfo>>('/users/_self/ip')
);

export const updateUserInfo = (userInfo: UserFormData) => (
  clientSession.put('/users/_self', userInfo)
);

export const UserWatchStatHistory = ({
  video_content_type,
  offset,
  count,
}: {
  video_content_type?: ContentType,
  offset?: number,
  count?: number
}) => (
  clientSession.get<never, ResponseContainer<historyData[]>>('/users/_self/profile', {
    params: {
      video_content_type,
      offset,
      count,
    },

  })
);

export const UserContinueWatching = ({ offset, count }: { offset?: number, count?: number }) => (
  clientSession.get<never, ResponseContainer<continueWatchingData[]>>('/users/_self/profile/continue-watching', {
    params: {
      offset,
      count,
    },
  })
);

export const UsersProfileInfo = () => (
  clientSession.get<never, ResponseContainer<MultiProfileSelectorProps[]>>('/users/profile/')
);

export const UserProfileInfo = ({ profileId }: { profileId?: string }) => (
  clientSession.get<never, ResponseContainer<MultiProfileSelectorProps>>(`/users/profile/${profileId}`)
);

export const CreateUserProfiles = (body: UpdateMultiProfile) => (
  clientSession.post<never, ResponseContainer<MultiProfileSelectorProps>>('/users/profile', body)
);

export const UpdateUserProfile = (profileId: string, body: UpdateMultiProfile) => (
  clientSession.put<never, ResponseContainer<MultiProfileSelectorProps>>(`/users/profile/${profileId}`, body)
);

export const ProfileSwitch = (body: SwitchToNewProfileType) => (
  clientSession.post<never, ResponseContainer<MultiProfileSwitch>>('/users/profile/switch', body)
);

export const SetProfilePin = (profileId: string, body: ProfilePinInterface) => (
  clientSession.put<never, ResponseContainer<MultiProfilePin>>(`/users/profile/pin/${profileId}`, body)
);

export const GetOtpAfterDeleteProfile = (profileId: string) => (
  clientSession.get<never, ResponseContainer<getOtpAfterDelete>>(`/users/profile/delete/${profileId}`)
);

export const GetOtpAfterResetProfile = (profileId: string) => (
  clientSession.get<never, ResponseContainer<getOtpAfterDelete>>(`/users/profile/reset-pin/${profileId}`)
);

export const ResetProfilePinOtpCheck = (profileId: string, body: {
  otp: string;
}) => (
  clientSession.post<never, ResponseContainer<MultiProfilePin>>(`/users/profile/reset-pin-otpcheck/${profileId}`, body)
);

export const ResetProfilePin = (profileId: string, body: ProfileResetPinInterface) => (
  clientSession.post<never, ResponseContainer<MultiProfilePin>>(`/users/profile/reset-pin/${profileId}`, body)
);

export const removeProfile = (profileId: string, body: { otp: string }) => (
  clientSession.post(`/users/profile/delete/${profileId}`, body)
);

export const FetchPersonalWidgets = () => (
  clientSession.get<never, ResponseContainer<continueWatchingData[]>>('/widgets/personal-home-page')
);

export const FetchProfileAvatar = () => (
  clientSession.get<never, ResponseContainer<IAvatarGroupType[]>>('/users/static-avatar')
);

export const removeUserContinueWatching = ({ id }: { id: string }) => (
  clientSession.delete(`/users/_self/profile/continue-watching/${id}`)
);

export const thirdPartyAuthentication = ({ token }) => (
  clientSession.post('/third-party-authentication',
    { token, device: getDeviceInfo() })
);

export const getPackages = (params?: { discountCode?: string, referalToken?: string }) => (
  clientSession.get<PackageType[], ResponseContainer<PackageType[], PackagesMeta>>('/packages', { params })
);

export const getUserVoucher = () => (
  clientSession.get<PackageListType, ResponseContainer<PackageListType, PackagesMeta>>('/packages/voucher')
);

export const postPackages = (body?: {
  discountCode?: string, referalToken?: string, return_url?: string,
  fail_callback_url?: string
}) => (
  clientSession.post<PackageType[], ResponseContainer<PackageType, PackagesMeta>>('/packages', body)
);

export const buySubscriptions = (body: SelectedPackage) => {
  return clientSession.post<SelectedPackage, ResponseContainer<SuccessSelectPackage>>('/subscriptions', body);
};

export const buyCinemaOnlineTicket = (body: CinemaOnlineSelectedPackage, id: string) => {
  return clientSession.post<CinemaOnlineSelectedPackage, ResponseContainer<SuccessSelectPackage>>(`video-contents/${id}/buy-ticket`, body);
};

export const buySubscriptionsForMobileUser = (body: SelectedPackage) => {
  return clientSession.post<SelectedPackage & {
    transaction_id: string
  }, ResponseContainer<SuccessSelectPackage>>('/subscriptions/pay', body);
};

export const getPaymentGateway = (approach_id: string, discountCode?: string) => (
  clientSession.get<never, ResponseContainer<paymentGateway>>(`/subscriptions/getPaymentGateway/${approach_id}${discountCode ? '/' + discountCode : ''}`)
);

export const getCinemaOnlinePaymentGateway = () => (
  clientSession.get<never, ResponseContainer<paymentGateway>>('/subscriptions/CinemaOnlinePaymentGateway')
);
export const getPaymentTransaction = (id: string) => (
  clientSession.get<never, ResponseContainer<PaymentTransaction>>(`/subscriptions/getPaymentTransaction/${id}`)
);

export const getCinemaTransaction = (id: string) => (
  clientSession.get<never, ResponseContainer<CinemaOnlineTransaction>>(`/cinema-online/GetTransaction/${id}`)
);

export const updateAvatarImage = (payload: FormData) => {
  return clientSession.post<any, ResponseContainer<User>>(selfUrl + '/avatar', payload, {
    headers: { ['Content-Type']: 'multipart/form-data' },
  });
};

export const fetchSearchResult = ({ query }) => (
  clientSession.get('/search', { params: { query } })
);

export const submitSurvey = ({ surveyId, ...body }: SubmitVoteRequest) => {
  return clientSession.post<any, ResponseContainer<any, Meta>>(`/surveys/${surveyId}`, body);
};

export const getSurvey = ({ referenceId }) => {
  return clientSession.get(`/surveys/${referenceId}`);
};

export const getUserSurvey = ({ reference }) => {
  return clientSession.get(`/surveys/${reference}/current-user`);
};

export const getUserSurveyGroup = ({ reference }) => {
  return clientSession.get(`/survey-groups/${reference}/current-user`);
};

export const likeContent = (id: string) => clientSession.post(`/video-contents/${id}/like`);
export const dislikeContent = (id: string) => clientSession.post(`/video-contents/${id}/dislike`);

export const buyCinemaTicket = ({ id, ...body }: buyCinemaTicketRequest) => (
  clientSession.post<never, ResponseContainer<SuccessSelectPackage>>(`/video-contents/${id}/buy-ticket`, body)
);

export const popUpMessaging = () => {
  return clientSession.get<popUpMessageData, ResponseContainer<popUpMessageData>>('/popup-message');
};

export const checkSso = ({ expiration_token }) => {
  return clientSession.get<never, ResponseContainer<CreateAccessTokenResponse>>('/sso/check', { params: { expiration_token } });
};
